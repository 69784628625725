// axios
import axios from 'axios'
import store from '@/store/store'


//let domain = "https://kafeinfo.com/api";
//let domain="https://beta.kafeinfo.com/api"
  let domain="http://localhost:8988";
//let domain="http://192.168.100.78:8988";

//domain="https://staging.kafeinfo.com/api"


if (process.env.NODE_ENV == 'production') {
  domain = "/api/";
} else if (process.env.NODE_ENV == 'staging') {
  domain = "/api/";
}

const instance = axios.create({
  baseURL: domain
  // You can add your headers here
})



instance.interceptors.request.use(function(config) {
  const token = store.getters['getToken'];
 
  if(token){
    if ( token != null ) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }
 
  return config;
}, function(err) {
  
  return Promise.reject(err);
});

instance.interceptors.response.use(
  function(response){     
    return response;
  }, 
  function(error){
      // handle error LOGIN_USER_INACTIVE
      let err = _.cloneDeep(error);
     // alert(JSON.stringify(err))
      console.log(JSON.stringify(err['response']))
      if(error && _.has(error,'response' ) && _.has(error.response ,"statusText" )   ){
        if(error.response["statusText"] == "UNAUTHORIZED"){
          localStorage.removeItem('token');
          localStorage.removeItem('userRole');
          localStorage.removeItem('user');
          localStorage.removeItem('LS_ROUTE_KEY');
          store.dispatch('logout');
          store.dispatch('logout').then(()=>{
            location.reload();
          });
          
         
         
          //this.showToster({message:'sdhsdhfdsf',isError:true})
         // alert(error.response.data.result.error.code)
         
         
        }
       
      }
          
      return Promise.reject(error);
      //return error;
  });
 



export default instance;
