


const getters = {
    getSubscriptions: state => state?state.subscriptions:null,
    getIsCardExists: state => state.isCardExists,
    getactiveCardList: state => state.cardDetails,
    getCurrentPlan:state => state.currentPlan,
}

export default getters