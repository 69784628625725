import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-multiselect-inv/dist/vue-multiselect.min.css'
import '@/scss/style.scss'
import '@/scss/reqstyles.scss'
import Vuesax from 'vuesax';
import 'vuesax/dist/vuesax.css';
Vue.use(Vuesax) 
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast);

import VueTimepicker from 'vue2-timepicker'
import 'vue2-datepicker/index.css';
import 'vue2-timepicker/dist/VueTimepicker.css'
Vue.use(VueTimepicker);
import './mixins/mixins.js'

import './filters'

import globalgonfig from './config';

Vue.config.productionTip = false
Vue.prototype.$globalgonfig = globalgonfig



// Form validators 
import "./vee-validate";

import VeeValidate from 'vee-validate';
Vue.use(VeeValidate);

import { Validator } from 'vee-validate';
//Vue.component('vue-phone-number-input', VuePhoneNumberInput);
import PhoneNumber from 'awesome-phonenumber'

import HighchartsVue from 'highcharts-vue';
Vue.use(HighchartsVue)

import CKEditor from '@ckeditor/ckeditor5-vue2';

Vue.use(CKEditor);


const phoneNumber = {
  getMessage: field => `${field} is not a valid phone number`,
  validate (value) {
    return new Promise(resolve => {
      let phone = new PhoneNumber(value);
      resolve({ valid: phone.isValid() })
    })
  }
}
Validator.extend('phoneNumber', phoneNumber)

const phonevalid = {
  getMessage: field => `${field} is not a valid phone number`,
  validate (value) {
    return new Promise(resolve => {
      value=value.replace(/\(|\)/g, '').replace(/-/g,'');
      value="+1"+value;
      let phone = new PhoneNumber(value,'US');
      resolve({ valid: phone.isValid() })
    })
  }
}
Validator.extend('phonevalid', phonevalid)


Validator.extend("maxval", {
 
  validate(value, args) {
    console.log(value + "====  "+JSON.stringify(args[0]));
    if(value<=parseInt(args[0])){
      return true;
    }else{
      return false;
    }
  },
  params: ['length'],
  message: 'Max value {length} is required'
});

Validator.extend("minval", {
 
  validate(value, args) {
    console.log(value + "====  "+JSON.stringify(args[0]));
    if(value>=parseInt(args[0])){
      return true;
    }else{
      return false;
    }
  },
  params: ['length'],
  message: 'Minimum value {length} is required'
});

Validator.extend('strongpassword', {
  getMessage: field => `The password must contain at least: 1 uppercase, 1 lowercase, 1 number, and one special character`,
  validate: value => {
      var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})");
      return strongRegex.test(value);
  }
});

Validator.extend('zipcodev', {
  getMessage: field => `Zip Code not valid`,
  validate:  (value, [other]) => {
    try{

       //  console.log(other)
    if( _.has(other, 'zipcodeLength')){
    
      return value.length == other['zipcodeLength'];

    } else if(other && _.has(other, 'id') && other.id == 231){

      return value.length == 5;

    }else if(other && _.has(other, 'id')  && other.id == 101){
      return value.length == 6;


    }else{
      return value.length == 6;
    }


    }catch(err){
      return value.length == 6;
    }
 

return true
  }
},{ hasTarget: true }); 





Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    this.$validator.localize('en', {
      messages: {
        required: (field) => '* ' + field + ' is required',
        date_format: (field, args) => `Date must be a valid format (MM/DD/YYYY)`
      },
    })
  }
}).$mount('#app')
