import * as _ from "lodash";
const mutations = {
    loading(state ,value){
     // state.show_loading =value;

  },
  UPDATE_SUBSCRIPTIONS(state,data){
    state.subscriptions = data
  },
  setIsCardExists(state ,data){
   
    state.cardDetails =[];
    state.isCardExists =null;
    if(data && _.has(data ,"list")){
      state.cardDetails = data['list']
     // alert(JSON.stringify(data))
      state.cardDetails =data['list'];
      if(data['list'].length>0){
        state.isCardExists =true;
      }else{
        state.isCardExists =false;
      }

    }
    localStorage.setItem('isCardExists' ,state.isCardExists)

   
  },
  updateCurrentPlan(state ,data){
    state.currentPlan =data;
    //alert(JSON.stringify(state.currentPlan));

  },
}

export default mutations