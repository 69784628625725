import axios from 'axios'
import store from '../store'
import _ from 'lodash';




let domain = "https://payments.kafeinfo.com/api/";

let  host = window.location.host;
host = host.toLowerCase();
const parts = host.split('.');
 
if (process.env.NODE_ENV == 'production') {
//  domain = "/payapi/"; //  paymentsbeta
}
const instance = axios.create({
    baseURL: domain
    // You can add your headers here
})

instance.interceptors.request.use(function (config) {
    const token = store.state.token;
    if (token != null) { //6H8Blp5yL/EJ2tBpfEK1EgGcWWo6zi1W75nEYQTcBoZvC/Aj
        config.headers.Authorization = "Bearer 7i8EkpgjLqsJ34Zpe0K1R1CbWTM/nHZWvpvAZ1DXV9ttDvAr";  //IMMIBOX
        //config.headers.Authorization = "Bearer uy5ekch3faoJidU/LEK1FACYWTA5xSNW687DM1fRB9psXPF8"; // anyclap

        if ( ['anyclap', '192.168.100.102:808', 'localhost:8080', 'localhost:8081', '192', 'localhost:8083'].indexOf(parts[0].toLowerCase()) > -1
         || (host.includes('anyclap.com'))
        ) {
          //  config.headers.Authorization = "Bearer uy5ekch3faoJidU/LEK1FACYWTA5xSNW687DM1fRB9psXPF8";
        }
        
    }
    return config;
}, function (err) {
    return Promise.reject(err);
});


const actions = {

    getSubscribePlans({ commit },postData) {
        return new Promise((resolve, reject) => {
          instance.post("/plans/list-for-end-users", postData)
            .then((response) => {
              resolve(response.data.result)
            })
            .catch((error) => {
              reject(error);
    
            })
        })
      },
      getSelectedPlan({ commit },postData) {
        return new Promise((resolve, reject) => {
          instance.post("/plans/get-selected-plan", postData)
            .then((response) => {
              resolve(response.data.result)
            })
            .catch((error) => {
              if (error.response) {
                console.log("Server responded with an error:");
                console.log("Status Code:", error.response.status);
                console.log("Error Data:", error.response.data);
              } else if (error.request) {
                console.log("No response received. Error details:", error.request);
              } else {
                console.log("Error setting up request:", error.message);
              }
              reject(error);
    
            })
        })
      },
      billingGetPaymentMethod({ commit },postData) {
        return new Promise((resolve, reject) => {
          instance.post("/billing/get-payment-methods", postData)
            .then((response) => {
              resolve(response.data.result)
            })
            .catch((error) => {
              if (error.response) {
                console.log("Server responded with an error:");
                console.log("Status Code:", error.response.status);
                console.log("Error Data:", error.response.data);
              } else if (error.request) {
                console.log("No response received. Error details:", error.request);
              } else {
                console.log("Error setting up request:", error.message);
              }
              reject(error); 
    
            })
        })
      },
      billingAttachPaymentMethod({ commit },postData) {
        return new Promise((resolve, reject) => {
          instance.post("/billing/attach-payment-method", postData)
            .then((response) => {
              resolve(response.data.result)
            })
            .catch((error) => {
              if (error.response) {
                console.log("Server responded with an error:");
                console.log("Status Code:", error.response.status);
                console.log("Error Data:", error.response.data);
              } else if (error.request) {
                console.log("No response received. Error details:", error.request);
              } else {
                console.log("Error setting up request:", error.message);
              }
              reject(error); 
    
            })
        })
      },
      planSubscribe({ commit },postData) {
        return new Promise((resolve, reject) => {
          instance.post("/plans/subscribe", postData)
            .then((response) => {
              resolve(response.data.result)
            })
            .catch((error) => {
              reject(error); 
    
            })
        })
      },
      invoiceListApi({ commit },postData) {
        return new Promise((resolve, reject) => {
          instance.post("/invoices/list", postData)
            .then((response) => {
              resolve(response.data.result)
            })
            .catch((error) => {
              reject(error);
            })
        })
      }, 
      validateCoupon({ commit },postData) {
        return new Promise((resolve, reject) => {
          instance.post("/coupons/validate", postData)
            .then((response) => {
              resolve(response.data.result)
            })
            .catch((error) => {
              reject(error);
            })
        })
      }   


}

export default actions